import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ShowSnackbar } from "../../../actions/snackbar";
import moment from "moment";
import { UploadReceipt } from "../../../actions/payment";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import { BANK_TRANSFER_PAY_MODE, USDT_TRC20_PAY_MODE } from "../../../constant";
import payment from "../../../reducers/payment";
import BackdropComponent from "../../../components/Backdrop";
// import {
//   SUMMARY_TEXT,
//   INITIAL_PAYMENT_TEXT,
//   INITIAL_PAYMENT_TO_TEXT,
//   FOLLOW_INSTRUCTION_TEXT,
//   MERCHANT_REFERENCE_TEXT,
//   TRANSFER_TEXT,
//   TRASNFER_FUND_TO_TEXT,
//   FUND_TRANSFER_FOLLOWING_TEXT,
//   RECIPIENT_NAME_TEXT,
//   RECIPIENT_ACCOUNT_NUMBER_TEXT,
//   AMOUNT_TEXT,
//   PAYMENT_REFERENCE_TEXT,
//   PAYMENT_REFERENCE_TOOLTIP_TEXT,
//   ATTENTION_TEXT_1,
//   ATTENTION_TEXT_2,
//   UPLOAD_RECEIPT_TEXT,
//   TAKE_SCREENSHOT_TEXT,
//   UPLOAD_TEXT,
//   IMAGE_FILE_ONLY_TEXT,
//   SUBMIT_TEXT,
//   NO_FILE_TEXT,
//   ONLY_JPG_PNG_FILE_TEXT,
//   IMAGE_MUST_LESS_TEXT,
//   UNABLE_UPLOAD_RECEIPT_TEXT,
//   RECEIPT_REQUIRED_TEXT,
//   SUBMITTED_RECEIPT_ON_TEXT,
//   SUBMIT_AGAIN_TEXT,
// } from "../../../constant";
interface PaymentData {
  paymentData: {
    succeeded: boolean;
    data: {
      bankName: string;
      bankBranchName: string;
      bankBranchCode: string;
      bankSwiftCode: string;
      beneficiaryName: string;
      beneficiaryAccountNumber: string;
      totalAmount: number;
      currency: string;
      referenceNumber: string;
      merchantReference: string;
      signature: string;
      status: number;
      updatedAt: string;
      merchantName: string;
      isUpload: boolean;
      paymentId: string;
      payMode: PayMode;
      redirectURL: string;
      usdtAddress: string;
      usdtName?: string;
      usdtqrCode?: string;
      uen: string;
    };
  };
}

interface PayMode {
  id?: string;
  payMode?: string;
  code?: string;
}

const PendingSection: React.FC<PaymentData> = ({ paymentData }) => {
  const { i18n, t } = useTranslation();
  const [fileName, setFileName] = useState(t("NO_FILE_TEXT"));
  const dispatch = useAppDispatch();
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      receipt: "",
    },
  });

  const handleChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    const isJpgOrPng =
      selectedFile?.type === "image/jpeg" ||
      selectedFile?.type === "image/png" ||
      selectedFile?.type === "image/jpg";

    // const isLt2M = selectedFile.size / 1024 / 1024 < 1;
    const file = Math.round(selectedFile?.size / 1024);
    const isLt5M = file >= 5120 ? true : false;

    if (!isJpgOrPng) {
      dispatch(ShowSnackbar(t("ONLY_JPG_PNG_FILE_TEXT"), "error"));
    } else if (isLt5M) {
      dispatch(ShowSnackbar(t("IMAGE_MUST_LESS_TEXT"), "error"));
    } else {
      //success case
      if (selectedFile) {
        const resizeImageBase64 = await resizeFile(selectedFile);
        if (resizeImageBase64) {
          setValue("receipt", resizeImageBase64.toString(), {
            shouldValidate: true,
          });
          setFileName(selectedFile.name);
        } else {
          dispatch(ShowSnackbar(t("UNABLE_UPLOAD_RECEIPT_TEXT"), "error"));
        }
      }
    }
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  // const getBase64 = async (file: any) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       resolve(reader.result);
  //     };
  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  const handleCopyUSDTAddress = (usdtAddress: string) => {
    navigator.clipboard.writeText(usdtAddress);
    dispatch(ShowSnackbar("Copy Successfully.", "success"));
  };
  const resetState = () => {
    reset();
    setFileName(t("NO_FILE_TEXT"));
  };

  const onReceiptSubmit = (values: any) => {
    if (!values.receipt) {
      dispatch(ShowSnackbar(t("RECEIPT_REQUIRED_TEXT"), "error"));
    } else {
      //Here dispatch to call api
      const data = {
        paymentId: paymentData?.data?.paymentId,
        receipt: values.receipt,
        signature: paymentData?.data?.signature,
        merchantReference: paymentData?.data?.merchantReference,
      };

      dispatch(UploadReceipt(data));
    }
  };

  const handleRedirectMerchant = (redirectURL: string) => {
    setRedirectLoading(true);
    setTimeout(() => {
      window.location.href = redirectURL;
    }, 5000);
  };

  const displayBankInfo = (paymentData: any) => {
    return (
      <>
        {paymentData?.bankInfo?.bankName ? (
          <div>
            <p className="transferSectionText">
              {/* Transfer Fund To */}
              {t("TRASNFER_FUND_TO_TEXT")}
            </p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.bankName}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.beneficiaryName ? (
          <div>
            <p className="transferSectionText">
              {t("RECIPIENT_NAME_TEXT")}
              {/* Recipient Name */}
            </p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.beneficiaryName}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.beneficiaryAccountNumber ? (
          <div>
            <p className="transferSectionText">
              {t("RECIPIENT_ACCOUNT_NUMBER_TEXT")}
              {/* Recipient Account Number */}
            </p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.beneficiaryAccountNumber}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.swiftCode ? (
          <div>
            <p className="transferSectionText">
              {t("SWIFT_CODE_AUD_BSB_TEXT")}
            </p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.swiftCode}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.bankBranchName ? (
          <div>
            <p className="transferSectionText">{t("BANK_BRANCH_NAME_TEXT")}</p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.bankBranchName}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.bankBranchCode ? (
          <div>
            <p className="transferSectionText">{t("BANK_BRANCH_CODE_TEXT")}</p>
            <p className="transferSectionValue">
              {paymentData?.bankInfo?.bankBranchCode}
            </p>
            <Divider />
          </div>
        ) : null}

        {paymentData?.bankInfo?.uen ? (
          <div>
            <p className="transferSectionText">{t("FPS_PAYID")}</p>
            <p className="transferSectionValue">{paymentData?.bankInfo?.uen}</p>
            <Divider />
          </div>
        ) : null}
      </>
    );
  };

  // const SwiftCodeControl = (bankInfo: any) => {
  //   //AUD = BSB
  //   //HKD = Wait to confirm
  //   //SGD = SWIFT CODE
  //   if (bankInfo?.currency === "AUD") {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("SWIFT_CODE_AUD_BSB_TEXT")}</p>
  //         <p className="transferSectionValue">{bankInfo?.swiftCode}</p>
  //         <Divider />
  //       </div>
  //     );
  //   } else if (bankInfo?.currency === "HKD") {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("SWIFT_CODE_TEXT")}</p>
  //         <p className="transferSectionValue">{bankInfo?.swiftCode}</p>
  //         <Divider />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("SWIFT_CODE_TEXT")}</p>
  //         <p className="transferSectionValue">{bankInfo?.swiftCode}</p>
  //         <Divider />
  //       </div>
  //     );
  //   }
  // };

  // const UENControl = (bankInfo: any) => {
  //   //AUD = PAY ID
  //   //HKD = FPS
  //   //SGD = UEN
  //   if (bankInfo?.currency === "AUD") {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("UEN_AUD_PAY_ID")}</p>
  //         <p className="transferSectionValue">{bankInfo?.uen}</p>
  //         <Divider />
  //       </div>
  //     );
  //   } else if (bankInfo?.currency === "HKD") {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("UEN_HKD_FPS")}</p>
  //         <p className="transferSectionValue">{bankInfo?.uen}</p>
  //         <Divider />
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <p className="transferSectionText">{t("BANK_UEN")}</p>
  //         <p className="transferSectionValue">{bankInfo?.uen}</p>
  //         <Divider />
  //       </div>
  //     );
  //   }
  // };

  const handleSubmitUI = () => {
    return (
      <>
        <section className="transferSection mt-2 mb-2">
          <p className="transferText">
            <span className="numberIcon">
              <LooksTwoIcon style={{ fontSize: "45px", color: "#5448c2" }} />
            </span>
            {t("UPLOAD_RECEIPT_TEXT")}
            {/* Upload Receipt */}
          </p>
          <p className="transferSectionText black">
            <span className="linearIcon"></span>
            {t("TAKE_SCREENSHOT_TEXT")}
            {/* Take a screenshot and upload transfer receipt. */}
          </p>
          {paymentData.data.isUpload ? (
            <Card className="uploadReceiptInfoCard">
              <CardContent>
                <div className="uploadReceiptInfoDiv">
                  <div>
                    <ErrorOutlineIcon
                      style={{ fontSize: "45px", color: "#5448c2" }}
                    />
                  </div>
                  <div style={{ marginLeft: "2rem" }}>
                    <p className="uploadReceiptInfoText">
                      {t("SUBMITTED_RECEIPT_ON_TEXT")}{" "}
                      {/* You have submitted a receipt on{" "} */}
                      {moment(paymentData.data.updatedAt).format("LLL")}.
                    </p>
                    <p className="uploadReceiptInfoText">
                      {t("SUBMIT_AGAIN_TEXT")}
                      {/* You can submit again to replace the previous receipt. */}
                    </p>
                    {paymentData?.data?.redirectURL ? (
                      <div className="mt-2">
                        <Button
                          variant="contained"
                          className="receiptSubmitBttn"
                          onClick={() =>
                            handleRedirectMerchant(
                              paymentData?.data?.redirectURL
                            )
                          }
                        >
                          {t("REDIRECT_BUTTON")}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : null}
          <form onSubmit={handleSubmit(onReceiptSubmit)}>
            <div className="mt-2" style={{ display: "flex" }}>
              <div>
                <input
                  {...register("receipt")}
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleChange}
                  id="receiptImage"
                />
                <label htmlFor="receiptImage">
                  <Button
                    variant="contained"
                    component="span"
                    className="uploadBttnStyle"
                  >
                    {t("UPLOAD_TEXT")}
                  </Button>
                </label>
              </div>
              <div style={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label={fileName}
                  fullWidth
                  disabled
                  InputProps={{
                    readOnly: true,
                    className: "fileChosenInput",
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon onClick={resetState} className="closeIcon" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <p className="fileUploadNote">{t("IMAGE_FILE_ONLY_TEXT")}</p>
            </div>
            <div className="mb-2 mt-2">
              <Button
                variant="contained"
                size="large"
                className="receiptSubmitBttn"
                type="submit"
              >
                {t("SUBMIT_TEXT")}
                {/* Submit */}
              </Button>
            </div>
          </form>
        </section>
      </>
    );
  };
  const BankTransferMode = () => {
    return (
      <>
        {/* Summary */}
        <h1>{t("SUMMARY_TEXT")}</h1>
        <div className="mt-2">
          <p className="summarySectionText">
            {/* Merchant Reference */}
            {t("MERCHANT_REFERENCE_TEXT")}: {paymentData.data.merchantReference}
          </p>
          <p className="summarySectionText">
            {/* You have initiated a payment of RM5 to Merchant01 */}
            {t("INITIAL_PAYMENT_TEXT")}
            <span className="bold">
              {paymentData.data.currency} {paymentData.data.totalAmount}
            </span>
            {t("INITIAL_PAYMENT_TO_TEXT")}
            <span className="italic">{paymentData.data.merchantName}</span>
            {/* {paymentData.data.merchantName}に{paymentData.data.totalAmount}{paymentData.data.currency}の支払いを開始
          You have initiated a payment of{" "}
          <span className="bold">
            {paymentData.data.currency} {paymentData.data.totalAmount}
          </span>{" "}
          to <span className="italic">{paymentData.data.merchantName}</span> */}
          </p>
          <p className="summarySectionText">
            {t("FOLLOW_INSTRUCTION_TEXT")}
            {/* Follow the instructions below to complete the payment. */}
          </p>
        </div>
        <Divider className="mt-2 mb-2" />
        <section className="transferSection">
          <p className="transferText">
            <span className="numberIcon">
              <LooksOneIcon style={{ fontSize: "45px", color: "#5448c2" }} />
            </span>
            {t("TRANSFER_TEXT")}
            {/* Transfer */}
          </p>

          <p className="transferSectionText black">
            <span className="linearIcon"></span>
            {t("FUND_TRANSFER_FOLLOWING_TEXT")}
            {/* Make a fund transfer with following information. */}
          </p>

          {displayBankInfo(paymentData?.data)}
          <div>
            <p className="transferSectionText">{t("AMOUNT_TEXT")}</p>
            <p className="transferSectionValue">
              {paymentData.data.currency} {paymentData.data.totalAmount}
            </p>
            <Divider />
          </div>

          <div>
            <p className="transferSectionText">
              {t("PAYMENT_REFERENCE_TEXT")}{" "}
              <Tooltip title={t("PAYMENT_REFERENCE_TOOLTIP_TEXT")}>
                <span className="infoIcon">
                  <InfoIcon style={{ fontSize: "20px", color: "#5448c2" }} />
                </span>
              </Tooltip>
              {/* <Tooltip title="*Please enter payment reference when you are processing bank transfer.">
            <span className="infoIcon">
              <InfoIcon style={{ fontSize: "20px", color: "#5448c2" }} />
            </span>
          </Tooltip> */}
            </p>
            <p className="transferSectionValue">
              {paymentData.data.referenceNumber}
            </p>
            <Divider />
          </div>

          <div className="mt-2">
            <p className="noteText">
              {t("ATTENTION_TEXT_1")} {paymentData.data.currency}
              {". "}
              {t("ATTENTION_TEXT_2")}
              {/* Note: this instruction should not be used for deposits in currencies
            other than {paymentData.data.currency}. It is critical that you
            provide your bank with accurate information when transferring funds.
            We are unable to assume responsibility for improperly applied
            instructions. */}
            </p>
          </div>
        </section>
        {/* <Divider className="mt-2 mb-2" /> */}
        {handleSubmitUI()}
      </>
    );
  };

  const USDTMode = () => {
    return (
      <>
        {/* Summary */}
        <h1>{t("SUMMARY_TEXT")}</h1>
        <div className="mt-2">
          <p className="summarySectionText">
            {/* Merchant Reference */}
            {t("MERCHANT_REFERENCE_TEXT")}: {paymentData.data.merchantReference}
          </p>
          <p className="summarySectionText">
            {/* You have initiated a payment of RM5 to Merchant01 */}
            {t("INITIAL_PAYMENT_TEXT")}
            <span className="bold">
              {paymentData.data.currency} {paymentData.data.totalAmount}
            </span>
            {t("INITIAL_PAYMENT_TO_TEXT")}
            <span className="italic">{paymentData.data.merchantName}</span>
            {/* {paymentData.data.merchantName}に{paymentData.data.totalAmount}{paymentData.data.currency}の支払いを開始
        You have initiated a payment of{" "}
        <span className="bold">
          {paymentData.data.currency} {paymentData.data.totalAmount}
        </span>{" "}
        to <span className="italic">{paymentData.data.merchantName}</span> */}
          </p>
          <p className="summarySectionText">
            {t("FOLLOW_INSTRUCTION_TEXT")}
            {/* Follow the instructions below to complete the payment. */}
          </p>
        </div>
        <Divider className="mt-2 mb-2" />
        <section className="transferSection">
          <p className="transferText">
            <span className="numberIcon">
              <LooksOneIcon style={{ fontSize: "45px", color: "#5448c2" }} />
            </span>
            {t("TRANSFER_TEXT")}
            {/* Transfer */}
          </p>

          <p className="transferSectionText black">
            <span className="linearIcon"></span>
            {t("FUND_TRANSFER_FOLLOWING_TEXT")}
            {/* Make a fund transfer with following information. */}
          </p>
          <div>
            <p className="transferSectionText">{t("AMOUNT_TEXT")}</p>
            <p className="transferSectionValue">
              {paymentData.data.currency} {paymentData.data.totalAmount}
            </p>
            <Divider />
          </div>

          <div>
            <p className="transferSectionText">
              {t("PAYMENT_REFERENCE_TEXT")}{" "}
              <Tooltip title={t("PAYMENT_REFERENCE_TOOLTIP_TEXT")}>
                <span className="infoIcon">
                  <InfoIcon style={{ fontSize: "20px", color: "#5448c2" }} />
                </span>
              </Tooltip>
            </p>
            <p className="transferSectionValue">
              {paymentData.data.referenceNumber}
            </p>
            <Divider />
          </div>

          <div>
            <p className="transferSectionText"></p>
            <p className="transferSectionValue">
              {paymentData?.data?.usdtName}
            </p>
          </div>
          {/* <div>
            <img
              src={paymentData?.data?.usdtqrCode}
              height="250"
              width="250"
              alt="USDT QR Code"
            />
          </div> */}
          <div>
            <p className="transferSectionValue">
              {paymentData?.data?.usdtAddress}{" "}
              <FileCopyIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyUSDTAddress(paymentData?.data?.usdtAddress)
                }
              />
            </p>
          </div>
          {/* {paymentData?.data?.redirectURL ? (
            <div className="mt-2">
              <Button
                variant="contained"
                className="receiptSubmitBttn"
                onClick={() =>
                  handleRedirectMerchant(paymentData?.data?.redirectURL)
                }
              >
                {t("REDIRECT_BUTTON")}
              </Button>
            </div>
          ) : null} */}

          <div className="mt-2">
            <p className="noteText">
              {/* {t("ATTENTION_TEXT_1")} {paymentData.data.currency}
              {". "}
              {t("ATTENTION_TEXT_2")} */}
              {t("ATTENTION_TEXT_1_USDT")} {paymentData.data.currency}{" "}
              {paymentData.data.totalAmount}, {t("ATTENTION_TEXT_2_USDT")}
            </p>
          </div>
        </section>
        {handleSubmitUI()}
      </>
    );
  };

  return (
    <Container>
      {redirectLoading ? (
        <BackdropComponent open={redirectLoading} word={t("REDIRECTING")} />
      ) : null}
      {paymentData?.data?.payMode?.code === BANK_TRANSFER_PAY_MODE
        ? BankTransferMode()
        : paymentData?.data?.payMode?.code === USDT_TRC20_PAY_MODE
        ? USDTMode()
        : null}
    </Container>
  );
};

export default PendingSection;
