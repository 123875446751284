import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { useState, useEffect, Fragment } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { GetBankConfirmation, UpdateBankId } from "../../actions/payment";
import { useSearchParams, useNavigate } from "react-router-dom";
import { home } from "../../constant";
import Spinner from "../../components/Spinner";
import AccountErrorSection from "./error";
const AccountPage = () => {
  const { i18n, t } = useTranslation();
  const [account, setAccount] = useState("");
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      accountId: "",
    },
  });

  const dispatch = useAppDispatch();
  const paymentSelector = useAppSelector((state) => state.payment);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.size === 0) {
      navigate(home);
    } else {
      const paymentId = searchParams.get("paymentId");
      const merchantReference = searchParams.get("merchantReference");
      const signature = searchParams.get("signature");

      if (paymentId && merchantReference && signature) {
        dispatch(GetBankConfirmation(paymentId, merchantReference, signature));
      }

      if (paymentSelector?.bankData?.data?.merchantLanguage) {
        i18n.changeLanguage(paymentSelector?.bankData?.data?.merchantLanguage);
      }
    }
  }, [
    searchParams,
    navigate,
    dispatch,
    i18n,
    paymentSelector?.bankData?.data?.merchantLanguage,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccount((event.target as HTMLInputElement).value);
    setValue("accountId", event.target.value);
  };

  const onBankAccountSubmit = (values: any) => {
    const data = {
      paymentId: paymentSelector?.bankData?.data?.paymentId,
      bankId: values?.accountId,
      signature: paymentSelector?.bankData?.data?.signature,
    };

    dispatch(UpdateBankId(data));
  };

  return (
    <Container>
      {paymentSelector?.isLoadingGetBankConfirmation ||
      paymentSelector?.isLoadingUpdateBankId ? (
        <Spinner />
      ) : paymentSelector?.isUpdateBankIdSuccess ? null : paymentSelector
          ?.bankData?.succeeded ? (
        <Fragment>
          <h1>{t("ACCOUNT_SELECT")}</h1>
          <div className="mt-2">
            <p className="summarySectionText">
              {t("MERCHANT_REFERENCE_TEXT")}:{" "}
              {paymentSelector?.bankData?.data.merchantReference}
            </p>
            <p className="summarySectionText">
              {t("INITIAL_PAYMENT_TEXT")}
              <span className="bold">
                {paymentSelector?.bankData?.data.currency}{" "}
                {paymentSelector?.bankData?.data.totalAmount}
              </span>
              {t("INITIAL_PAYMENT_TO_TEXT")}
              <span className="italic">
                {paymentSelector?.bankData?.data?.merchantName}
              </span>
            </p>
          </div>
          <Divider className="mt-2 mb-2" />
          <section className="transferSection">
            <p className="summarySectionText">{t("CHOOSE_ACCOUNT")}</p>
            <div className="width100">
              <form onSubmit={handleSubmit(onBankAccountSubmit)}>
                <FormControl className="width100" {...register("accountId")}>
                  <RadioGroup onChange={handleChange}>
                    {paymentSelector?.bankData?.data?.accountList?.length > 0
                      ? paymentSelector?.bankData?.data?.accountList?.map(
                          (item: any, index: number) => {
                            return (
                              <FormControlLabel
                                key={index}
                                className={
                                  account === item.id
                                    ? "radioDivSelected"
                                    : "radioDiv"
                                }
                                value={item.id}
                                control={<Radio />}
                                label={
                                  <div className="p-1">
                                    <p>Bank: {item.bankName}</p>
                                    <p>
                                      Beneficiary Name: {item.beneficiaryName}
                                    </p>
                                    <p>
                                      Account Number:{" "}
                                      {item.beneficiaryAccountNumber}
                                    </p>
                                    <p>UEN/PAYNOW: {item.uen}</p>
                                  </div>
                                }
                              />
                            );
                          }
                        )
                      : null}
                  </RadioGroup>
                </FormControl>
                <div className="mb-2 mt-2">
                  <Button
                    variant="contained"
                    size="large"
                    className="receiptSubmitBttn"
                    type="submit"
                    disabled={account ? false : true}
                  >
                    {t("SUBMIT_TEXT")}
                  </Button>
                </div>
              </form>
            </div>
          </section>
        </Fragment>
      ) : !paymentSelector?.bankData ? null : (
        <AccountErrorSection />
      )}
    </Container>
  );
};

export default AccountPage;
