/* eslint-disable import/no-anonymous-default-export */
import {
  GET_PAYMENT_CONFIRMATION_FAILURE,
  GET_PAYMENT_CONFIRMATION_REQUEST,
  GET_PAYMENT_CONFIRMATION_SUCCESS,
  UPLOAD_RECEIPT_FAILURE,
  UPLOAD_RECEIPT_REQUEST,
  UPLOAD_RECEIPT_SUCCESS,
  GET_BANK_ACCOUNT_CONFIRMATION_FAILURE,
  GET_BANK_ACCOUNT_CONFIRMATION_REQUEST,
  GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS,
  UPDATE_BANK_ID_REQUEST,
  UPDATE_BANK_ID_SUCCESS,
  UPDATE_BANK_ID_FAILURE,
} from "../actions/action";

const INITIAL_STATE = {
  payment: null,
  isLoadingGetPaymentConfirmation: false,
  isLoadingUploadReceipt: false,
  bankData: null,
  isLoadingGetBankConfirmation: false,
  isLoadingUpdateBankId: false,
  isUpdateBankIdSuccess: false,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PAYMENT_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoadingGetPaymentConfirmation:
          action.payload.isLoadingGetPaymentConfirmation,
      };
    case GET_PAYMENT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isLoadingGetPaymentConfirmation:
          action.payload.isLoadingGetPaymentConfirmation,
        payment: action.payload.paymentData,
      };
    case GET_PAYMENT_CONFIRMATION_FAILURE:
      return {
        ...state,
        isLoadingGetPaymentConfirmation:
          action.payload.isLoadingGetPaymentConfirmation,
        payment: action.payload.errorPaymentData,
      };
    case UPLOAD_RECEIPT_REQUEST:
      return {
        ...state,
        isLoadingUploadReceipt: action.payload.isLoadingUploadReceipt,
      };
    case UPLOAD_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoadingUploadReceipt: action.payload.isLoadingUploadReceipt,
      };
    case UPLOAD_RECEIPT_FAILURE:
      return {
        ...state,
        isLoadingUploadReceipt: action.payload.isLoadingUploadReceipt,
      };
    case GET_BANK_ACCOUNT_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoadingGetBankConfirmation:
          action.payload.isLoadingGetBankConfirmation,
      };
    case GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isLoadingGetBankConfirmation:
          action.payload.isLoadingGetBankConfirmation,
        bankData: action.payload.bankData,
      };
    case GET_BANK_ACCOUNT_CONFIRMATION_FAILURE:
      return {
        ...state,
        isLoadingGetBankConfirmation:
          action.payload.isLoadingGetBankConfirmation,
        bankData: action.payload.errorBankData,
      };
    case UPDATE_BANK_ID_REQUEST:
      return {
        ...state,
        isLoadingUpdateBankId: action.payload.isLoadingUpdateBankId,
      };
    case UPDATE_BANK_ID_SUCCESS:
      return {
        ...state,
        isLoadingUpdateBankId: action.payload.isLoadingUpdateBankId,
        isUpdateBankIdSuccess: action.payload.isUpdateBankIdSuccess,
      };
    case UPDATE_BANK_ID_FAILURE:
      return {
        ...state,
        isLoadingUpdateBankId: action.payload.isLoadingUpdateBankId,
        isUpdateBankIdSuccess: action.payload.isUpdateBankIdSuccess,
      };
    default:
      return state;
  }
};
