import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { PAYMENT_ERROR_TEXT } from "../../../constant";
import { useTranslation } from "react-i18next";

function ErrorSection() {
  const { t } = useTranslation();
  return (
    <div className="paymentResultDiv">
      <div className="mt-2">
        <WarningAmberIcon className="paymentResultIcon" />
      </div>
      <p className="paymentResultSectionText">{t('PAYMENT_ERROR_TEXT')}</p>
    </div>
  );
}
export default ErrorSection;
