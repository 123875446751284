//SnackBar
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//Payment Confirmation
export const GET_PAYMENT_CONFIRMATION_REQUEST = "GET_PAYMENT_CONFIRMATION_REQUEST";
export const GET_PAYMENT_CONFIRMATION_SUCCESS = "GET_PAYMENT_CONFIRMATION_SUCCESS";
export const GET_PAYMENT_CONFIRMATION_FAILURE = "GET_PAYMENT_CONFIRMATION_FAILURE";

//Upload Receipt
export const UPLOAD_RECEIPT_REQUEST = "UPLOAD_RECEIPT_REQUEST";
export const UPLOAD_RECEIPT_SUCCESS = "UPLOAD_RECEIPT_SUCCESS";
export const UPLOAD_RECEIPT_FAILURE = "UPLOAD_RECEIPT_FAILURE";

//Bank Account Confirmation
export const GET_BANK_ACCOUNT_CONFIRMATION_REQUEST = "GET_BANK_ACCOUNT_CONFIRMATION_REQUEST";
export const GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS = "GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS";
export const GET_BANK_ACCOUNT_CONFIRMATION_FAILURE = "GET_BANK_ACCOUNT_CONFIRMATION_FAILURE";

//Update BankId
export const UPDATE_BANK_ID_REQUEST = "UPDATE_BANK_ID_REQUEST";
export const UPDATE_BANK_ID_SUCCESS = "UPDATE_BANK_ID_SUCCESS";
export const UPDATE_BANK_ID_FAILURE = "UPDATE_BANK_ID_FAILURE";