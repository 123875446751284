import {
  GET_BANK_ACCOUNT_CONFIRMATION_FAILURE,
  GET_BANK_ACCOUNT_CONFIRMATION_REQUEST,
  GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS,
  GET_PAYMENT_CONFIRMATION_FAILURE,
  GET_PAYMENT_CONFIRMATION_REQUEST,
  GET_PAYMENT_CONFIRMATION_SUCCESS,
  UPLOAD_RECEIPT_FAILURE,
  UPLOAD_RECEIPT_REQUEST,
  UPLOAD_RECEIPT_SUCCESS,
  UPDATE_BANK_ID_REQUEST,
  UPDATE_BANK_ID_SUCCESS,
  UPDATE_BANK_ID_FAILURE,
} from "./action";

import axios from "../axiosConfig";
import { ShowSnackbar } from "./snackbar";
const PAYMENT_ENDPOINT = "/api/Payment";
const BANK_ENDPOINT = "/api/BankInfo";

export const GetPaymentConfirmation = (
  paymentId: string,
  merchantReference: string,
  signature: string
) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_PAYMENT_CONFIRMATION_REQUEST,
      payload: { isLoadingGetPaymentConfirmation: true },
    });
    axios
      .get(
        PAYMENT_ENDPOINT +
          "/Get?paymentId=" +
          paymentId +
          "&merchantReference=" +
          merchantReference +
          "&signature=" +
          signature
      )
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_CONFIRMATION_SUCCESS,
          payload: {
            paymentData: res?.data,
            isLoadingGetPaymentConfirmation: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PAYMENT_CONFIRMATION_FAILURE,
          payload: {
            isLoadingGetPaymentConfirmation: false,
            errorPaymentData: err?.response?.data,
          },
        });
      });
  };
};

export const GetBankConfirmation = (
  paymentId: string,
  merchantReference: string,
  signature: string
) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_BANK_ACCOUNT_CONFIRMATION_REQUEST,
      payload: { isLoadingGetBankConfirmation: true },
    });
    axios
      .get(
        BANK_ENDPOINT +
          "/GetBankInfo?paymentId=" +
          paymentId +
          "&merchantReference=" +
          merchantReference +
          "&signature=" +
          signature
      )
      .then((res) => {
        dispatch({
          type: GET_BANK_ACCOUNT_CONFIRMATION_SUCCESS,
          payload: {
            bankData: res?.data,
            isLoadingGetBankConfirmation: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BANK_ACCOUNT_CONFIRMATION_FAILURE,
          payload: {
            isLoadingGetBankConfirmation: false,
            errorBankData: err?.response?.data,
          },
        });
      });
  };
};

export const UploadReceipt = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPLOAD_RECEIPT_REQUEST,
      payload: { isLoadingUploadReceipt: true },
    });
    axios
      .post(PAYMENT_ENDPOINT + "/Upload", data)
      .then((res) => {
        dispatch(
          GetPaymentConfirmation(
            data.paymentId,
            data.merchantReference,
            data.signature
          )
        );
        dispatch({
          type: UPLOAD_RECEIPT_SUCCESS,
          payload: { isLoadingUploadReceipt: false },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPLOAD_RECEIPT_FAILURE,
          payload: { isLoadingUploadReceipt: false },
        });
        dispatch(ShowSnackbar("Upload receipt error!", "error"));
      });
  };
};

export const UpdateBankId = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_BANK_ID_REQUEST,
      payload: { isLoadingUpdateBankId: true },
    });
    axios
      .post(PAYMENT_ENDPOINT + "/Proceed", data)
      .then((res) => {
        dispatch({
          type: UPDATE_BANK_ID_SUCCESS,
          payload: { isLoadingUpdateBankId: false, isUpdateBankIdSuccess: true },
        });

        var url = res?.data?.data?.paymentUrl;
        window.location = url;
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BANK_ID_FAILURE,
          payload: { isLoadingUpdateBankId: false, isUpdateBankIdSuccess: false },
        });
        dispatch(ShowSnackbar("Account Error!", "error"));
      });
  };
};
