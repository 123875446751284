export const home = "notfound";
export const payment_confirmation = "payment/confirmation";
export const invalid_url = "/*";
export const payment_success = "payment/success";
export const payment_fail = "payment/fail";
export const payment_account = "payment/account-select";

export const BANK_TRANSFER_PAY_MODE = "bank_transfer";
export const USDT_TRC20_PAY_MODE = "usdt_transfer";

//Page translation text
//PAGE NOT FOUND
export const PAGE_NOT_FOUND_TEXT = "ไม่พบหน้านี้";

//Sorry. We are unable to verify your receipt.
export const PAYMENT_FAIL_TEXT = "ขอโทษ. เราไม่สามารถตรวจสอบใบเสร็จของคุณได้";

//Thank you. Your payment has been received.
export const PAYMENT_SUCCESS_TEXT = "ขอบคุณ ได้รับการชำระเงินของคุณแล้ว";

//Unable to proceed payment. Please contact admin.
export const PAYMENT_ERROR_TEXT =
  "ไม่สามารถดำเนินการชำระเงินได้ กรุณาติดต่อผู้ดูแลระบบ.";

//Summary
export const SUMMARY_TEXT = "สรุป";

//Merchant Reference
export const MERCHANT_REFERENCE_TEXT = "การอ้างอิงผู้ขาย";

//Initial Payment
export const INITIAL_PAYMENT_TEXT = "คุณได้เริ่มต้นการชำระเงินจำนวน ";

//To
export const INITIAL_PAYMENT_TO_TEXT = " ถึง ";

//Follow the instructions below to complete the payment.
export const FOLLOW_INSTRUCTION_TEXT =
  "ทำตามคำแนะนำด้านล่างเพื่อชำระเงินให้เสร็จสิ้น";

//Transfer
export const TRANSFER_TEXT = "โอนย้าย";

//Make a fund transfer with following information.
export const FUND_TRANSFER_FOLLOWING_TEXT =
  "ทำการโอนเงินโดยมีข้อมูลดังต่อไปนี้";

//Transfer Fund To
export const TRASNFER_FUND_TO_TEXT = "ธนาคาร";

//Recipient Name
export const RECIPIENT_NAME_TEXT = "ชื่อบัญชี";

//Recipient Account Number
export const RECIPIENT_ACCOUNT_NUMBER_TEXT = "เลขที่บัญชี";

//Amount
export const AMOUNT_TEXT = "จำนวนเงินทั้งหมด";

//Payment Reference
export const PAYMENT_REFERENCE_TEXT = "หลักฐานการจ่ายเงิน";

//Please enter payment reference when you are processing bank transfer.
export const PAYMENT_REFERENCE_TOOLTIP_TEXT =
  "กรุณาป้อนข้อมูลอ้างอิงการชำระเงินเมื่อคุณดำเนินการโอนเงินผ่านธนาคาร";

//Note: this instruction should not be used for deposits in currencies other than
export const ATTENTION_TEXT_1 =
  "หมายเหตุ: ไม่ควรใช้คำสั่งนี้สำหรับการฝากเงินในสกุลเงินนอกเหนือจาก";

//It is critical that you provide your bank with accurate information when transferring funds. We are unable to assume responsibility for improperly applied instructions.
export const ATTENTION_TEXT_2 =
  "มัเป็นสิ่งสำคัญที่คุณให้ข้อมูลที่ถูกต้องแก่ธนาคารของคุเมื่อทำการโอนเงินเราไม่สามารถรับผิดชอบต่อการใช้งานที่ไม่เหมาะสมได้คำแนะนำ.";

//Upload Receipt
export const UPLOAD_RECEIPT_TEXT = "อัพโหลดใบเสร็จรับเงิน";

//Take a screenshot and upload transfer receipt.
export const TAKE_SCREENSHOT_TEXT = "ถ่ายภาพหน้าจอและอัพโหลดใบเสร็จการโอนเงิน";

//Upload
export const UPLOAD_TEXT = "ที่อัพโหลด";

//Image files only. (Maximum size 5MB)
export const IMAGE_FILE_ONLY_TEXT = "ไฟล์ภาพเท่านั้น (ขนาดสูงสุด 5MB)";

//Submit
export const SUBMIT_TEXT = "ส่ง";

//No File
export const NO_FILE_TEXT = "ไม่มีไฟล์";

//Only JPG/PNG files can be uploaded!
export const ONLY_JPG_PNG_FILE_TEXT =
  "สามารถอัพโหลดได้เฉพาะไฟล์ JPG/PNG เท่านั้น!";

//Image must be less than 5MB!
export const IMAGE_MUST_LESS_TEXT = "รูปภาพต้องมีขนาดไม่เกิน 5MB!";

//Unable to upload receipt!
export const UNABLE_UPLOAD_RECEIPT_TEXT = "ไม่สามารถอัปโหลดใบเสร็จได้!";

//Receipt is required!
export const RECEIPT_REQUIRED_TEXT = "ต้องมีใบเสร็จรับเงิน!";

//You have submitted receipt on
export const SUBMITTED_RECEIPT_ON_TEXT = "คุณได้ส่งใบเสร็จรับเงินเมื่อ ";

//You can submit again to replace the previous receipt.
export const SUBMIT_AGAIN_TEXT =
  "คุณสามารถส่งอีกครั้งเพื่อทดแทนใบเสร็จรับเงินก่อนหน้าได้";
