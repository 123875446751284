import "../../assets/payment.scss";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { home } from "../../constant";
import { GetPaymentConfirmation } from "../../actions/payment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import PendingSection from "./sections/pending";
import Spinner from "../../components/Spinner";
import ErrorSection from "./sections/error";
import PaymentSuccess from "../PaymentSuccessPage";
import PaymentFail from "../PaymentFailPage";
import { useTranslation } from "react-i18next";

function Payment() {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const paymentSelector = useAppSelector((state) => state.payment);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.size === 0) {
      navigate(home);
    } else {
      const paymentId = searchParams.get("paymentId");
      const merchantReference = searchParams.get("merchantReference");
      const signature = searchParams.get("signature");

      if (paymentId && merchantReference && signature) {
        dispatch(
          GetPaymentConfirmation(paymentId, merchantReference, signature)
        );
      }

      if (paymentSelector?.payment?.data?.merchantLanguage) {
        i18n.changeLanguage(paymentSelector?.payment?.data?.merchantLanguage);
      }
    }
  }, [
    searchParams,
    navigate,
    dispatch,
    i18n,
    paymentSelector?.payment?.data?.merchantLanguage,
  ]);

  return (
    <>
      {paymentSelector?.isLoadingGetPaymentConfirmation ||
      paymentSelector?.isLoadingUploadReceipt ? (
        <Spinner />
      ) : paymentSelector?.payment?.succeeded ? (
        paymentSelector?.payment?.data?.status === 0 ? (
          <PendingSection paymentData={paymentSelector?.payment} />
        ) : paymentSelector?.payment?.data?.status === 1 ? (
          <PaymentSuccess />
        ) : (
          <PaymentFail />
        )
      ) : !paymentSelector?.payment ? null : (
        <ErrorSection />
      )}
    </>
  );
}

export default Payment;
