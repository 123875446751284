import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "../../assets/backdrop.scss";

interface BackdropProps {
  open: boolean;
  word: string;
}
const BackdropComponent: React.FC<BackdropProps> = ({ open, word }) => {
  return (
    <Backdrop className="backdrop" open={open}>
      <div>
        <CircularProgress color="inherit" />
      </div>
      <div className="customWordDiv">
        <p className="customWord">{word}</p>
      </div>
    </Backdrop>
  );
};

export default BackdropComponent;
