import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MainRoute from "./routes";
import PaymentHeader from "./components/PaymentHeader/paymentHeader";
import "./i18n";

function App() {
  return (
    console.log(process.env.REACT_APP_API_URL),
    (
      <>
        <PaymentHeader />
        <MainRoute />
      </>
    )
  );
}

export default App;
