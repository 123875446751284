import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { PAYMENT_SUCCESS_TEXT } from "../../constant";
import { useTranslation } from "react-i18next";
function PaymentSuccess() {
  const { t } = useTranslation();
  return (
    <div className="paymentResultDiv">
      <div className="mt-2">
        <CheckCircleOutlineIcon className="paymentResultIcon" />
      </div>
      <p className="paymentResultSectionText">
        {t("PAYMENT_SUCCESS_TEXT")}
        {/* Thank you. Your payment has been received. */}
      </p>
    </div>
  );
}

export default PaymentSuccess;
