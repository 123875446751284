import { Routes, Route, Navigate } from "react-router-dom";
import Payment from "./views/PaymentPage";
import {
  home,
  payment_confirmation,
  invalid_url,
  payment_account,
} from "./constant";
import HomePage from "./views/HomePage";
import AccountPage from "./views/AccountPage";
const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path={home} element={<HomePage />} />
      <Route path={payment_confirmation} element={<Payment />} />
      <Route path={invalid_url} element={<Navigate to="/" />} />
      <Route path={payment_account} element={<AccountPage />} />
      {/* <Route path={payment_success} element={<PaymentSuccess />} />
      <Route path={payment_fail} element={<PaymentFail />} /> */}
    </Routes>
  );
};

export default MainRoute;
