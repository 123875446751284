import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PAYMENT_FAIL_TEXT } from "../../constant";
import { useTranslation } from "react-i18next";
function PaymentFail() {
  const { t } = useTranslation();
  return (
    <div className="paymentResultDiv">
      <div className="mt-2">
        <HighlightOffIcon className="paymentResultIcon" />
      </div>
      <p className="paymentResultSectionText">
        {t("PAYMENT_FAIL_TEXT")}
        {/* Sorry. We are unable to verify your receipt. */}
      </p>
    </div>
  );
}

export default PaymentFail;
