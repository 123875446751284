import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../../assets/spinner.scss";
import Backdrop from "@mui/material/Backdrop";
export default function CircularIndeterminate() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress className="spinnerStyle" />
    </Backdrop>
  );
}
