import { PAGE_NOT_FOUND_TEXT } from "../../constant";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
function HomePage() {
  const { i18n, t } = useTranslation();

  // useEffect(() => {
  //   console.log(window.location.origin);
  //   i18n.changeLanguage("th");
  // },[]);
  return (
    // page Not Found
    <div>{t('PAGE_NOT_FOUND_TEXT')}</div>
  );
}

export default HomePage;
